import React from 'react';
import Header from './components/Header';
import Projects from './components/Projects';
import ContactInfo from './components/ContactInfo';
import Footer from './components/Footer';

const App = () => (
  <div style={{ padding: '0px 10px 0px 10px'}}>
    <Header />
    <Projects />
    <ContactInfo />
    <Footer />
  </div>
);

export default App;
