import React from 'react';
import Project from './Project';
import { projects } from '../data';

const Projects = () => (
  <div>
    <h2 style={{ fontWeight: 'bold', color: '#1e90ff' }}>projects</h2>
    <ul
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0,
      }}
    >
      {projects.map(p =>
        <Project {...p} key={p.url} />)}
    </ul>
  </div>
);

export default Projects;
