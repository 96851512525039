import React from 'react';
import ContactLink from './ContactLink';
import { contactLinks } from '../data';

const ContactInfo = () => (
  <div>
    <h2 style={{ fontWeight: 'bold', color: '#1e90ff' }}>contact</h2>
    <ul
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        padding: 0,
        flexWrap: 'wrap',
      }}
    >
      {contactLinks.map(cl =>
        <ContactLink {...cl} key={cl.url} />)}
    </ul>
  </div>
);

export default ContactInfo;