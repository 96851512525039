import React from 'react';
import PropTypes from 'prop-types';

const ContactLink = ({
  name,
  url,
  icon,
  onClick,
}) => (
  <li className="contact-item" onClick={onClick}>
    <a href={url} target="_new">
      <span>{icon()}</span>
      <span>{name}</span>
    </a>
  </li>
);

ContactLink.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default ContactLink;
